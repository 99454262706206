<template>
  <div
    v-if="userIdSelect"
    class="w-full h-[90px] flex items-center relative justify-between border-b border-b-ems-gray700 px-[10px]"
  >
    <div class="flex items-center justify-center space-x-[23px]">
      <div
        :class="`rounded-full w-[64px] h-[64px]  flex justify-center items-center relative flex-shrink-0`"
      >
        <div
          class="rounded-full w-[64px] h-[64px] flex justify-center items-center overflow-hidden flex-shrink-0"
        >
          <img alt="avatar" :src="userIdSelect.avatar" class="w-ful h-auto" />
        </div>
        <div
          class="h-[16px] w-[16px] border-2 rounded-full border-ems-tag1 bg-ems-boTro4_600 absolute bottom-0 right-0"
        ></div>
      </div>
      <div class="space-y-[8px]">
        <p class="text-white font-semibold text-[16px]">
          {{ userIdSelect.userName }}
        </p>
        <p class="text-ems-gray200 font-semibold text-[12px]">
          {{ t('coordinator.online') }}
        </p>
      </div>
    </div>
    <div class="flex items-center space-x-5">
      <button
        v-if="userIdSelect.idUser && idConnect === userIdSelect.idUser"
        @click="handleCancel"
        class="h-[37px] w-[85px] flex items-center justify-center bg-ems-main2 rounded-lg text-white"
      >
        {{ t('common.cancel_short') }}
      </button>
      <button
        v-else
        @click="handleConnect"
        class="h-[37px] w-[85px] flex items-center justify-center bg-ems-main2 rounded-lg text-white"
      >
        {{ t('coordinator.connect') }}
      </button>
      <img alt="close" src="@/static/img/icon/close.png" class="cursor-pointer" @click="handleClose"/>
    </div>
    
  </div>
  <div
    class="w-full flex justify-center items-center flex-col space-y-5 mt-[2rem]"
  >
    <div
      class="w-[10vw] h-[10vw] border-8 border-ems-gray500 bg-ems-tag1 flex justify-center items-center rounded-full cursor-pointer select-none"
      @mousedown="startMicrophone"
      @mouseup="stopMicrophone"
    >
      <div
        :class="`w-full h-full ${
          isMicrophoneOn ? 'bg-ems-main2' : 'bg-ems-gray400'
        } rounded-full flex justify-center items-center`"
      >
        <img
          v-if="isMicrophoneOn"
          alt="mic"
          src="@/static/img/icon/mic-open.svg"
          draggable="false"
        />
        <img
          v-else
          alt="mic"
          src="@/static/img/icon/mic.svg"
          draggable="false"
        />
      </div>
    </div>
    <div>
      <p class="font-semibold text-[20px] text-ems-gray300">
        {{ t('coordinator.enter_voice') }}
      </p>
    </div>
    <p class="text-ems-gray100 font-semibold text-[16px]">
      {{ formattedTime }}
    </p>
    <div
      class="flex space-x-[10px] items-center h-[60px] justify-center overflow-hidden"
    >
      <div v-for="(voice, entryIndex) in volumeArray" :key="entryIndex">
        <div
          :class="`h-[${
            voice * 2
          }px] min-h-[15px] w-[5px] rounded-full bg-ems-gray500`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  onUnmounted,
  watchEffect,
  computed,
  watch,
  defineEmits,
  onMounted,
} from 'vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { useI18n } from 'vue-i18n';
const { state, dispatch } = useStore();
const props = defineProps({
  action: VueTypes.string.def(''),
  idTrinhsat: VueTypes.string.def(''),
});

const itemValue = computed(() => state.coordinator.itemValue || '');
const idConnect = computed(() => state.coordinator.idConnected);
let microphoneStream = null;
let audioContext = null;
let analyser = null;
const isMicrophoneOn = ref(false);
const volumeArray = ref(Array(12).fill(0));
const emit = defineEmits(['startRecording', 'stopTalking']);
const minutes = ref(0);
const seconds = ref(0);
const secondsCopy = ref(0);
let mediaRecorder;
let recordedChunks = [];
let intervalId;
const roomId = computed(() => state.coordinator.roomID);
const userIdSelect = ref('');
const devices = computed(() => state.deviceManagement.devices);
const { t } = useI18n();
const formattedTime = computed(() => {
  const formattedMinutes = String(minutes.value).padStart(2, '0');
  const formattedSeconds = String(seconds.value).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
});

watch(idConnect, (newValue) => {
  userIdSelect.value = {
    ...userIdSelect.value,
    idUser: newValue,
  };
});
const startTime = () => {
  if (intervalId) return;

  intervalId = setInterval(() => {
    if (seconds.value === 59) {
      seconds.value = 0;
      secondsCopy.value = 0;
      minutes.value += 1;
    } else {
      seconds.value += 1;
      secondsCopy.value += 1;
    }
  }, 1000);
};
watch(
  () => props.idTrinhsat,
  (newId, oldId) => {
    userIdSelect.value = devices.value.find(
      (device) => device.userId === newId
    );
  }
);
onMounted(() => {
  userIdSelect.value = devices.value.find(
    (device) => device.userId === props.idTrinhsat
  );
});
const stopTime = () => {
  clearInterval(intervalId);
  intervalId = null;
  seconds.value = 0;
  minutes.value = 0;
  volumeArray.value = Array(12).fill(null);
};

const startMicrophone = () => {
  if (idConnect.value === userIdSelect.value.idUser) {
    dispatch('coordinator/startTalking');
    startTime();
    isMicrophoneOn.value = true;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        audioContext = new AudioContext();
        microphoneStream = stream;
        const source = audioContext.createMediaStreamSource(stream);
        analyser = audioContext.createAnalyser();
        source.connect(analyser);
        analyser.fftSize = 256;
        analyzeVolume();
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.push(event.data);
          }
        };
        mediaRecorder.start();
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  }

  // emit('startRecording');
};

const stopMicrophone = () => {
  if (idConnect.value === userIdSelect.value.idUser) {
    dispatch('coordinator/stopTalking');
    isMicrophoneOn.value = false;
    stopTime();
    if (audioContext) {
      audioContext.close();
      audioContext = null;
      analyser = null;
      microphoneStream.getTracks().forEach((track) => track.stop());
    }
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
      isMicrophoneOn.value = false;
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });
        const audioFile = new File([audioBlob], 'recording.webm', {
          type: 'audio/webm',
        });
        recordedChunks = [];
        customRequest(audioFile);
        if (microphoneStream) {
          microphoneStream.getTracks().forEach((track) => track.stop());
        }
        if (audioContext) {
          audioContext.close();
        }
      };
    }
  }

  // emit('stopTalking');
};
const analyzeVolume = () => {
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  const analyze = () => {
    if (!isMicrophoneOn.value) return;
    setTimeout(() => {
      analyser.getByteFrequencyData(dataArray);
      const averageVolume =
        dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
      volumeArray.value.push(Math.floor(averageVolume));
      if (volumeArray.value.length > 12) {
        volumeArray.value.shift();
      }
      analyze();
    }, 200);
  };
  analyze();
};
const handleMouseUp = () => {
  stopMicrophone();
};

onMounted(() => {
  window.addEventListener('mouseup', handleMouseUp);
});

onUnmounted(() => {
  window.removeEventListener('mouseup', handleMouseUp);
  stopMicrophone();
});
const handleSendMessages = (url) => {
  let mess = {
    receiverId: props.idTrinhsat,
    receiverType: 1,
    messageType: 'file',
    content: '',
    mediaType: `audio`,
    length: secondsCopy.value,
    url: url,
  };
  dispatch('coordinator/sendMessages', mess)
};

const customRequest = async (file) => {
  if (secondsCopy.value > 0) {
    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    fmData.append('file', file);
    fmData.append('rootPath', 'image');
    try {
      const response = await DataService.upload(
        ConstantAPI.upload_service.UPLOAD,
        fmData,
        config
      );
      checkResponse(response, () => {
        handleSendMessages(response.data.url);
        secondsCopy.value = 0
      });
    } catch (err) {
      console.log(err);
    }
  }
};

const handleConnect = async () => {
  await handleCancel();
  let payload = {
    receiverId: userIdSelect.value.userId,
    receiverType: 1,
    phoneType: 'voice',
  };
  dispatch('coordinator/createPhoneRoom', payload).then((data) => {
    dispatch('coordinator/handleGetRoomID', data.roomId);
    dispatch('coordinator/joinChannel', {
      appId: window.VUE_APP_API_AGORA_ID,
      channel: data.roomId,
      token: null,
      uuid: null,
    });
    dispatch('coordinator/joinRoom', {
      roomId: data.roomId,
    });
    dispatch('coordinator/handleChangeStatus', userIdSelect.value.userId);
  });
};
const handleCancel = async () => {
  let value = {
    roomId: roomId.value,
  };
  await dispatch('coordinator/leaveRoom', value);
  await dispatch('coordinator/handleChangeStatus', null);
  await dispatch('coordinator/leaveChannel');
};
const handleClose = ()=>{
  dispatch('coordinator/handleSetComponent', null)
}
</script>
